import { Container, Paper, Typography } from "@material-ui/core"
import Link from "@material-ui/core/Link"
import { Link as GatsbyLink, PageProps } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import React from "react"
import styled from "styled-components"
import ContactButton from "../components/base/contactButton"
import Center from "../components/base/layout/center"
import Spacer from "../components/base/layout/spacer"
import { flexCenter } from "../theme/globalStyles"

const Wrapper = styled(Paper)`
  flex-direction: column;
  ${flexCenter};
  padding: ${({ theme }) => `${theme.spacing(4)}px ${theme.spacing(1)}px`};
`

const NotFoundPage: React.FC<PageProps> = () => (
  <>
    <GatsbySeo title="404: Niet gevonden" />
    <Container maxWidth="md">
      <Wrapper>
        <Typography component="h1" variant="h3" align="center">
          404: Helaas, niet gevonden
        </Typography>
        <Spacer minHeight={28} />
        <Typography align="center">
          Helaas, de pagina die uw zocht bestaat niet meer.
        </Typography>
        <Spacer minHeight={16} />
        <Link component={GatsbyLink} to="/">
          Klik hier om naar de home pagina te gaan.
        </Link>
        <Spacer minHeight={36} />
        <Center>
          <ContactButton />
        </Center>
      </Wrapper>
    </Container>
    <Spacer minHeight={76} />
  </>
)

export default NotFoundPage
